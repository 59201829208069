
import { mapState, mapGetters } from 'vuex'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
SwiperCore.use([Navigation, Pagination])
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    mixins: [cms, playGames],
    data() {
        return {
            dragXStart: 0,
            featuredContent1ItemWidth: 280,
        }
    },
    computed: {
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
        }),
        ...mapGetters({
            featuredContent1Getter: 'cms/featuredContent1',
        }),
        featuredContent1() {
            return this.featuredContent1Getter(this.$config.THEME)
        },
    },
    methods: {
        onBack() {
            const elParent = document.getElementsByClassName(
                'w-featured-game-slider'
            )
            if (!elParent || !elParent[0]) return
            const elBack =
                elParent[0].getElementsByClassName('swiper-button-prev') //
            if (!elBack || !elBack[0]) return
            elBack[0].dispatchEvent(new MouseEvent('click'))
        },
        onNext() {
            const elParent = document.getElementsByClassName(
                'w-featured-game-slider'
            )
            if (!elParent || !elParent[0]) return
            const elNext =
                elParent[0].getElementsByClassName('swiper-button-next')
            if (!elNext || !elNext[0]) return
            elNext[0].dispatchEvent(new MouseEvent('click'))
        },
        getContentWidth() {
            const client = process.client
            if (client) {
                // Get featuredContent1 content width when size change
                const val = this.$refs?.refFeaturedGames?.clientWidth
                if (val) {
                    // Display only 4 card if more than that should click arrow right icon above
                    this.featuredContent1ItemWidth = Math.ceil(val / 4) - 12
                }
            } else {
                setTimeout(() => {
                    this.getContentWidth()
                }, 1000)
            }
        },
    },
}
