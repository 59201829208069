// State
export const state = () => ({
    tempData: {},
    thirdPartyPayments: [],
    depositFlow: {},
    checkExist: {},
    checkDeposit: false,
    companyCryptoWallet: [],
    checkExistLoading: false,
    createDepositLoading: false,
    createCryptoDepositLoading: false,
})

// Actions
export const actions = {
    async create({ commit }, data) {
        const form = { ...data }
        commit('setCreateDepositLoading', true)
        try {
            const response = await this.$axios.post(
                `/deposits/bank-transfer`,
                form
            )
            commit('setTempData', response.data)
            commit('setCreateDepositLoading', false)
            return response.data
        } catch (error) {
            commit('setCreateDepositLoading', false)
        }
    },
    async confirm({ commit }, id) {
        try {
            await this.$axios.put(`/deposits/${id}`, {})
            commit('checkDeposit', true)
            return true
        } catch (error) {
            return false
        }
    },
    async revoke({ commit }, id) {
        try {
            await this.$axios.put(`/deposits/revoke/${id}`, {})
            commit('checkDeposit', false)
            commit('setTempData', {})
            return true
        } catch (error) {
            return false
        }
    },
    async checkExist({ commit }, _) {
        try {
            commit('setCheckExistLoading', true)
            const response = await this.$axios.get(`/deposits/check/is-exists`)
            commit('setCheckExistLoading', false)
            commit('checkExist', response.data)
            return response.data
        } catch (error) {
            commit('setCheckExistLoading', false)
            return null
        }
    },
    async getThirdPartyPayments({ commit }, _) {
        try {
            const response = await this.$axios.get(
                'third-party-payments/providers'
            )
            commit('setThirdPartyPayments', response.data)
        } catch (error) {}
    },
    async depositFlow({ commit }, currency) {
        try {
            const response = await this.$axios.get(
                `website-setting/deposit-flow/${currency}`
            )
            commit('depositFlow', response.data)
            // commit('depositFlow', {
            // 	deposit_type: 2,
            // 	is_bank_transfer_enabled: true,
            // 	is_third_party_payment_enabled: true
            // })
        } catch (error) {}
    },
    async depositCrypto({ commit }, data) {
        commit('setCreateCryptoDepositLoading', true)
        try {
            const response = await this.$axios.post(`deposits/crypto`, data)
            commit('setCreateCryptoDepositLoading', false)
            return response.data
        } catch (error) {
            commit('setCreateCryptoDepositLoading', false)
            return false
        }
    },
    async createDepositThirdParty({ commit }, data) {
        const windowLink = window.open()
        try {
            const response = await this.$axios.post(
                `deposits/third-party`,
                data
            )
            commit('setTempData', response.data)
            windowLink.location = getThirdPartyPaymentLinkByProvider(
                response.data.tp_payment_link,
                response.data.tp_gateway_code,
                this.$router
            )
            return response.data
        } catch (error) {
            windowLink.close()
            return false
        }
    },
    async getCompanyCryptoWallet({ commit }, params) {
        try {
            const response = await this.$axios.get(
                `/deposits/settings/crypto`,
                {
                    params,
                }
            )
            commit('setCompanyCryptoWallet', response.data)
            return response.data
        } catch (error) {
            return false
        }
    },
    async getCompanyBankAccountQr({ _ }, { id, type = 'bank' }) {
        try {
            const bankAccountUrl = '/company-bank-account/qr'
            const cryptoUrl = '/company-crypto-wallet/qr'
            const url = type === 'bank' ? bankAccountUrl : cryptoUrl

            const response = await this.$axios.get(`${url}/${id}`)
            return response.data
        } catch (error) {
            return false
        }
    },
}

// Mutations
export const mutations = {
    setTempData: (state, data) => {
        state.tempData = data
    },
    setThirdPartyPayments: (state, data) => {
        state.thirdPartyPayments = data
    },
    depositFlow: (state, data) => {
        state.depositFlow = data
    },
    checkExist: (state, data) => {
        state.checkExist = data
    },
    checkDeposit: (state, data) => {
        state.checkDeposit = data
    },
    setCompanyCryptoWallet: (state, data) => {
        state.companyCryptoWallet = data
    },
    setCheckExistLoading: (state, data) => {
        state.checkExistLoading = data
    },
    setCreateDepositLoading: (state, data) => {
        state.createDepositLoading = data
    },
    setCreateCryptoDepositLoading: (state, data) => {
        state.createCryptoDepositLoading = data
    },
}

// Function to generate payment links for different payment providers
const paymentLinkProviders = {
    third_party_papaya_pay: (link, router) =>
        `${link}?redirectionUrl=${window.location.origin}${router.currentRoute.fullPath}`,
}

const getThirdPartyPaymentLinkByProvider = (link, provider, router) => {
    const generateLink = paymentLinkProviders[provider]
    return generateLink ? generateLink(link, router) : link
}
