
import { mapState } from 'vuex'
export default {
    name: 'Theme3DesktopProfileDropdown',
    computed: {
        ...mapState({
            player: (state) => state.player.player,
        }),
    },
    methods: {
        getLimitNickname(str) {
            if (!str) return null
            if (str.length <= 7) return str
            return `${str.substring(0, 7)}...`
        },
    },
}
