// State
export const state = () => ({
    loading: false,
    banks: [],
    accounts: [],
    created: true,
    cardHolderName: '',
    cancelToken: {},
    bankOptionsList: [
        {
            value: 1,
            text: 'bankList',
        },
        {
            value: 2,
            text: 'manuallyInputBank',
        },
    ],
})

// Actions
export const actions = {
    // Banks
    async banks({ commit, state }, params = {}) {
        try {
            params.currency = 'THB' // Lao WL will have only THB base currency
            const response = await this.$axios.get(`/banks`, {
                params,
            })
            commit('banks', response.data)
        } catch (error) {
            return false
        }
    },
    // Player accounts
    async accounts({ commit }, payload) {
        try {
            commit('loading', true)
            if (!this.$cookie.get('token')?.length > 0) {
                commit('loading', false)
                return
            }
            const response = await this.$axios.get(`/player-bank-account`, {
                params: payload,
            })
            commit('accounts', response.data.player_bank_accounts)
            commit('setCardHolderName', response.data.account_holder_name)
        } catch (error) {
        } finally {
            commit('loading', false)
        }
    },
}

// Mutations
export const mutations = {
    loading: (state, type) => {
        state.loading = type
    },
    banks: (state, v) => {
        state.banks = v
    },
    accounts: (state, v) => {
        state.accounts = v
    },
    setCardHolderName: (state, v) => {
        state.cardHolderName = v
    },
    setCancelTokens: (state, data) => {
        const oldData = { ...state.cancelToken }
        state.cancelToken = {
            ...oldData,
            ...data,
        }
    },
}

// Getters
export const getters = {
    banks: (state) => state.banks,
    bankListWithName: (state) => (locale) => {
        if (!locale) return []
        const banksListFormat = state.banks.map((el) => {
            let name = ''
            if (el.display_name) {
                if (el.display_name[locale]) {
                    name = el.display_name[locale]
                } else if (el.display_name['en-US']) {
                    name = el.display_name['en-US']
                } else {
                    const firstBankExistLocale = Object.keys(el.display_name)[0]
                    if (el.display_name[firstBankExistLocale]) {
                        name = el.display_name[firstBankExistLocale]
                    }
                }
            }
            return {
                ...el,
                name,
            }
        })
        return banksListFormat
    },
}
