
import { mapGetters, mapState } from 'vuex'
import cms from '~/mixins/common/cms'
export default {
    name: 'Theme3PopupComponent',
    mixins: [cms],
    data: () => ({
        maintain: false,
    }),
    computed: {
        ...mapGetters({
            getCMS: 'cms/getCMS',
            splashScreen: 'cms/splashScreen',
        }),
        ...mapState({
            dialog: (state) => state.settings.displayPopup,
            loading: (state) => state.settings.changeLanguageLoading,
            operationSetting: (state) => state.settings.operationSetting,
        }),
        isImageExist() {
            let isExist = false
            if (!this.splashScreen?.items) {
                isExist = false

                return false
            }

            this.splashScreen?.items.forEach((item) => {
                const getImage = this.cmsImageLink({
                    path:
                        (item?.image_path &&
                            item?.image_path[this.handleCookie('wl_locale')]) ||
                        '',
                    code: this.splashScreen.component_code,
                })
                if (getImage) isExist = true
            })
            return isExist
        },
    },
    watch: {
        operationSetting(value) {
            if (value) {
                this.maintain = !value.website_maintenance.status
            }
        },
    },
    methods: {
        closePopup() {
            this.$store.commit('settings/setDisplayPopup', false)
        },
    },
}
