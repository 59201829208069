
import { mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
export default {
    mixins: [cms],
    data() {
        return {
            minHeight: 900,
        }
    },
    computed: {
        ...mapGetters({
            desktopBackground: 'cms/desktopBackground',
            mobileBackground: 'cms/mobileBackground',
        }),
    },
    mounted() {
        if (window) {
            this.minHeight = window.innerHeight - 130
        }
    },
}
