export default {
    computed: {
        defaultWebsiteData() {
            return this.$store.state.settings.defaultWebsiteData
        },
        isRecaptchaActive() {
            return this.$store.state.settings.defaultWebsiteData
                .recaptcha_status
        },
    },
    async mounted() {
        try {
            // By default, the recaptcha will init itself onMounted event
            // So just add one more condition to check if the recaptcha is active or not
            if (this.defaultWebsiteData.recaptcha_status === false) return
            if (this.$recaptcha.version === 3) {
                await this.$recaptcha.init()
            }
        } catch (e) {}
    },
    methods: {
        async getRecaptchaToken(executeType = 'login') {
            // Ignore the recaptcha generation process if the recaptcha status is not active
            if (this.defaultWebsiteData.recaptcha_status === false) return true
            try {
                if (this.$recaptcha.version === 2) {
                    // for version 2
                    return await this.$recaptcha.getResponse()
                } else if (this.$recaptcha.version === 3) {
                    // for version 3
                    return await this.$recaptcha.execute(executeType)
                } else {
                    return false
                }
            } catch (e) {}
        },
        resetRecaptcha() {
            if (this.defaultWebsiteData.recaptcha_status === false) return true
            try {
                if (this.$recaptcha.version === 2) {
                    this.$recaptcha.reset()
                } else if (this.$recaptcha.version === 3) {
                    this.$recaptcha.destroy()
                    this.$recaptcha.init()
                }
            } catch (error) {}
        },
    },
    beforeDestroy() {
        try {
            if (this.$recaptcha.version === 2) {
                this.$recaptcha.reset()
            } else if (this.$recaptcha.version === 3) {
                this.$recaptcha.destroy()
            }
        } catch (error) {}
    },
}
