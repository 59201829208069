
import { mapState } from 'vuex'
export default {
    name: 'Theme3ProfileLayout',
    props: {
        icon: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        },
        showSideMenu: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            editNickname: false,
            formData: {
                nickname: '',
            },
        }
    },
    computed: {
        ...mapState({
            player: (state) => state.player.player,
        }),
    },
    methods: {
        showEditNickname() {
            this.formData.nickname = this.player.nickname
            this.editNickname = !this.editNickname
        },
        updateNickname() {
            if (
                this.formData.nickname &&
                this.formData.nickname !== this.player.nickname
            ) {
                this.$store.dispatch('themes/player/doUpdatePlayerProfile', {
                    ...this.formData,
                })
            }
            this.editNickname = false
        },
        getLimitedName(text) {
            if (!text) return null
            const displayLimit = 30
            if (text.length > displayLimit) {
                return text.substring(0, displayLimit) + '...'
            } else {
                return text
            }
        },
    },
}
