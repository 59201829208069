
export default {
    name: 'Theme3PlayerIdValidationCard',
    props: {
        username: {
            type: String,
            default: '',
        },
    },
    computed: {
        usernameLowerCase() {
            return this.username?.toLowerCase()
        },
        first() {
            const step1Regex = /^[a-z]/
            if (step1Regex.test(this.usernameLowerCase.charAt(0))) return true
            return false
        },
        length() {
            return (
                this.usernameLowerCase?.length >= 5 &&
                this.usernameLowerCase?.length <= 12
            )
        },
        format() {
            const step3Regex = /^[a-z][a-z0-9]{3,10}[a-z0-9]$/
            const isValidStep3 = step3Regex.test(this.usernameLowerCase)
            return isValidStep3
        },
    },
}
