// State
export const state = () => ({
    loading: false,
    companyBankAccount: [],
    companyBankAccountByCurrency: {},
})

// Actions
export const actions = {
    // Get company bank account
    async getCompanyBankPlayerAccount({ commit, rootState }, params = {}) {
        if (rootState.settings.cancelToken.companyBankAccount) {
            rootState.settings.cancelToken.companyBankAccount.cancel('')
        }
        this.commit('settings/setCancelToken', {
            companyBankAccount: this.$axios.CancelToken.source(),
        })
        commit('setLoading', true)
        try {
            const response = await this.$axios.get(
                '/deposits/company-bank-account',
                {
                    params,
                    cancelToken:
                        rootState.settings.cancelToken.companyBankAccount.token,
                }
            )
            commit('setCompanyBankAccount', response.data)
            if (params?.currency) {
                commit('setCompanyBankAccountByCurrency', {
                    [params.currency]: response.data,
                })
            }
        } catch (error) {
        } finally {
            commit('setLoading', false)
        }
    },
}

// Mutations
export const mutations = {
    setLoading: (state, v) => {
        state.loading = v
    },
    setCompanyBankAccount: (state, v) => {
        const filter = v.sort((a, b) => {
            if (a.currency < b.currency) {
                return -1
            } else if (a.currency > b.currency) {
                return 1
            } else {
                return 0
            }
        })
        state.companyBankAccount = filter
    },
    setCompanyBankAccountByCurrency: (state, v) => {
        state.companyBankAccountByCurrency = {
            ...state.companyBankAccountByCurrency,
            ...v,
        }
    },
}

// Getters
export const getters = {
    companyBankAccount: (state) => state.companyBankAccount,
    loading: (state) => state.loading,
    getCompanyBankAccountByCurrency: (state) => (currency) => {
        return state.companyBankAccountByCurrency[currency] || []
    },
}
