
import { mapState, mapGetters } from 'vuex'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
SwiperCore.use([Navigation, Pagination])
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    mixins: [cms, playGames],
    data() {
        return {
            dragXStart: 0,
            featuredContent2ItemWidth: 280,
            intervalCheck: null,
        }
    },
    computed: {
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
        }),
        ...mapGetters({
            featuredContent2Getter: 'cms/featuredContent2',
        }),
        featuredContent2() {
            return this.featuredContent2Getter(this.$config.THEME)
        },
    },
    beforeDestroy() {
        try {
            clearInterval(this.intervalCheck)
        } catch (error) {}
    },
    methods: {
        onBack() {
            const elParent = document.getElementsByClassName(
                'w-featured-provider-slider'
            )
            if (!elParent || !elParent[0]) return
            const elBack =
                elParent[0].getElementsByClassName('swiper-button-prev') //
            if (!elBack || !elBack[0]) return
            elBack[0].dispatchEvent(new MouseEvent('click'))
        },
        onNext() {
            const elParent = document.getElementsByClassName(
                'w-featured-provider-slider'
            )
            if (!elParent || !elParent[0]) return
            const elNext =
                elParent[0].getElementsByClassName('swiper-button-next')
            if (!elNext || !elNext[0]) return
            elNext[0].dispatchEvent(new MouseEvent('click'))
        },
        getContentWidth() {
            const client = process.client
            if (client) {
                // Get featuredContent2 content width when size change
                const val = this.$refs?.refFeaturedGames?.clientWidth
                if (val) {
                    // Display only 4 card if more than that should click arrow right icon above
                    this.featuredContent2ItemWidth = Math.ceil(val / 4) - 12
                }
            } else {
                setTimeout(() => {
                    this.getContentWidth()
                }, 1000)
            }
        },
        setItemModel(idx, url) {
            if (this.slideModel !== idx) {
                this.slideModel = idx
                clearInterval(this.intervalCheck)
                this.setupInterval()
            } else {
                this.openNewBrowserTab(url)
            }
        },
        setupInterval() {
            this.intervalCheck = setInterval(() => {
                if (this.slideModel + 1 < this.activitySlider?.items?.length) {
                    this.slideModel += 1
                } else {
                    this.slideModel = 0
                }
            }, 5000)
        },
    },
}
