
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({
            onHoldWallet: (state) => state.player.onHoldWallet,
            player: (state) => state.player.player,
        }),
    },
}
