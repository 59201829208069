
import fingerprint from '~/mixins/fingerprint'
import ReCaptcha from '~/mixins/recaptcha'
import register from '~/mixins/pages/register/register'
import { PLAYER_REGEX } from '~/utils/constants'

export default {
    name: 'Theme3LoginModal',
    mixins: [fingerprint, ReCaptcha, register],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            isConditional: false,
        }
    },
    watch: {
        acceptCondition(value) {
            if (value) {
                this.isConditional = false
            } else {
                this.isConditional = true
            }
        },
    },
    created() {
        if (this.supportCountries.length) {
            this.country = 'LA'
            this.formData.currency = this.supportCountries[0]?.default_currency
        }
    },
    async mounted() {
        this.$store.dispatch('banks/banks')
        this.formData.reg_fingerprint = await this.visitorId()

        const countryCode = this.$cookie.get('country_code')
        this.formData.country_code = countryCode
    },
    methods: {
        onCloseModal() {
            this.modal = false
            this.$store.commit('themes/settings/setDisplayRegisterModal', false)
            this.$emit('onCloseModal')
            this.$emit('close')
        },
        async register() {
            if (this.acceptCondition) {
                if (this.$refs.form.validate()) {
                    if (this.formData.player_id) {
                        this.formData.player_id =
                            this.formData?.player_id?.toLowerCase()
                        if (!PLAYER_REGEX.test(this.formData.player_id)) {
                            return
                        }
                    }
                    try {
                        this.loading = true
                        this.formData.recaptchaToken =
                            await this.getRecaptchaToken('register')

                        this.formData.registrationOption = 1

                        const { redirect, res } = await this.$store.dispatch(
                            'auth/registration',
                            this.formData
                        )

                        if (res) {
                            this.modal = false
                            this.$emit('onCloseModal')
                            this.$store.commit(
                                'themes/settings/setDisplayRegisterModal',
                                false
                            )

                            // Delay WebSocket initialization to ensure previous operations are fully completed
                            setTimeout(async () => {
                                await this.initSocketTheme()
                            }, 1000)
                            this.$router.push(redirect)
                        } else {
                            this.resetRecaptcha()
                        }
                        this.loading = false
                    } catch (error) {
                        this.loading = false
                    }
                }
            } else {
                this.isConditional = true
                this.$toast.error(this.tl('pleaseCheckCheckCondition'))
            }
        },
    },
}
