
export default {
    name: 'TransactionStatus',
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        isMobileScreen: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        isTimeMoreThan10Minutes() {
            const givenTime = this.$dayjs(this.item.created_at)
            const now = this.$dayjs()

            return now.diff(givenTime, 'minute') > 10
        },
    },
    methods: {
        async clickTrick(evt) {
            if (this.item.status === 1) {
                const confirm = window.confirm(this.tl('uSureToCancel?'))
                if (!confirm) return
                this.loading = true
                const result = await this.$store.dispatch(
                    'themes/withdraw/cancelWithdraw',
                    this.item.id
                )
                if (result) {
                    this.$toast.success(this.tl(result.message))
                    this.$emit('api')
                }
                this.loading = false
            }
        },
    },
}
