import { render, staticRenderFns } from "./ProfileLayout.vue?vue&type=template&id=c2dbd556"
import script from "./ProfileLayout.vue?vue&type=script&lang=js"
export * from "./ProfileLayout.vue?vue&type=script&lang=js"
import style0 from "./ProfileLayout.vue?vue&type=style&index=0&id=c2dbd556&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme3ComponentsSideMenu: require('/app/components/Theme3/Components/SideMenu.vue').default})
