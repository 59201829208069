
export default {
    name: 'Theme3ExchangeRateModal',
    props: {
        value: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        modal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
}
