
import fingerprint from '~/mixins/fingerprint'
import ReCaptcha from '~/mixins/recaptcha'
import Login from '~/mixins/pages/login/login'

export default {
    name: 'Theme3LoginModal',
    mixins: [fingerprint, ReCaptcha, Login],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        countryCode: {
            type: String,
            default: 'LA',
        },
    },
    data() {
        return {
            showLoginForm: true,
        }
    },
    computed: {
        modal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
        isRecaptchaActive() {
            return this.$store.state.settings.defaultWebsiteData
                .recaptcha_status
        },
    },
    async mounted() {
        const rememberUsername = this.$cookie.get('un')
        const rememberPhone = this.$cookie.get('pn')
        const loginOption = this.$cookie.get('login_option')

        if (rememberUsername) this.form.player_id = rememberUsername
        if (rememberPhone) this.form.phone = rememberPhone
        if (loginOption && ['phone', 'username'].includes(loginOption))
            this.loginType = loginOption

        this.form.reg_fingerprint = await this.visitorId()
    },
    methods: {
        onCloseModal() {
            this.modal = false
            this.$emit('onCloseModal')
        },
    },
}
