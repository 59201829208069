const colorDefinition = {
    USD: '#6A74F8',
    LAK: '#EA5885',
    THB: '#27A179',
}

export const state = () => ({
    currencies: [],
    getCurrencyLoading: false,
    depositMinMaxAmount: {},
})

export const actions = {
    async getAllCurrencies({ commit, state }, params) {
        // The loading will be shown only when the currencies length is 0
        if (state.currencies.length === 0) {
            commit('setGetCurrencyLoading', true)
        }
        try {
            await this.$axios
                .get(`/currency`, {
                    params,
                })
                .then((response) => {
                    commit('setCurrency', response.data)
                })
        } catch (error) {
            return false
        }
        commit('setGetCurrencyLoading', false)
    },
    async getDepositMinMaxForFiat({ commit }, currency) {
        try {
            await this.$axios
                .get(`/deposits/settings/bank-transfer`, {
                    params: {
                        currency,
                    },
                })
                .then((response) => {
                    const parseV = {
                        min_deposit: parseFloat(response.data.min_deposit || 0),
                        max_deposit: parseFloat(response.data.max_deposit || 0),
                    }
                    commit('setDepositMinMaxAmount', {
                        [currency]: parseV,
                    })
                })
        } catch (error) {
            return false
        }
    },
}

export const mutations = {
    setGetCurrencyLoading: (state, v) => {
        state.getCurrencyLoading = v
    },
    setCurrency: (state, v) => {
        const priorityOrder = ['LAK', 'THB', 'USD']

        const currencyWithColor = v.map((el) => {
            return {
                ...el,
                color: colorDefinition[el.code] || null,
            }
        })

        state.currencies = currencyWithColor.sort((a, b) => {
            const indexA = priorityOrder.indexOf(a.code)
            const indexB = priorityOrder.indexOf(b.code)

            if (indexA === -1 && indexB === -1) {
                return 0 // Both are not in the priority order, keep their default order
            }
            if (indexA === -1) {
                return 1 // a is not in the priority order, b comes first
            }
            if (indexB === -1) {
                return -1 // b is not in the priority order, a comes first
            }

            return indexA - indexB // Compare based on the priority order
        })
    },
    setDepositMinMaxAmount: (state, v) => {
        state.depositMinMaxAmount = {
            ...state.depositMinMaxAmount,
            ...v,
        }
    },
}

export const getters = {
    getCurrencyByType: (state) => (type) => {
        if (!type) return state.currencies
        return state.currencies.filter((el) => el.type === type)
    },
    getCurrencyColor: (state) => (currency) => {
        return colorDefinition[currency]
    },
    getCurrencySymbol: (state) => (currency) => {
        return state.currencies.find((el) => el.code === currency)?.symbol
    },
    getMinMaxDepositByCurrency: (state) => (currency) => {
        return state.depositMinMaxAmount[currency] ?? {}
    },
    getMinMaxWithdrawalByCurrency: (state) => (currency) => {
        if (currency) {
            const findMatchCurrency = state.currencies.find(
                (el) => el.code === currency
            )
            if (findMatchCurrency) {
                return {
                    withdraw_max: parseFloat(
                        findMatchCurrency.withdraw_max || 0
                    ),
                    withdraw_min: parseFloat(
                        findMatchCurrency.withdraw_min || 0
                    ),
                }
            }
        }
        return {}
    },
}
