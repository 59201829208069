
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({
            player: (state) => state.player.player,
        }),
        isPlayGamePage() {
            if (this.$route.path === '/play-game') return true
            return false
        },
    },
}
