import { render, staticRenderFns } from "./Popup.vue?vue&type=template&id=0c8686c3&scoped=true"
import script from "./Popup.vue?vue&type=script&lang=js"
export * from "./Popup.vue?vue&type=script&lang=js"
import style0 from "./Popup.vue?vue&type=style&index=0&id=0c8686c3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c8686c3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsNuxtImage: require('/app/components/Commons/NuxtImage.vue').default})
