
import { mapGetters, mapState } from 'vuex'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
export default {
    name: 'Theme3DesktopSlider',
    mixins: [cms, playGames],
    data() {
        return {
            slider: 0,
            sliderItemHeight: 60,
            sliders: [1, 2, 3, 4],
            sliderWidth: 733,
            sliderHeight: 250,
        }
    },
    computed: {
        imageWidth() {
            return Math.floor((this.sliderItemHeight * 1320) / 450) - 48
        },
        ...mapGetters({
            desktopSlider: 'cms/desktopSlider',
        }),
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
        }),
    },
    methods: {
        getSliderImageWidth() {
            const client = process.client
            if (client) {
                // Get slider width when size change
                const val = this.$refs?.refSlider?.clientWidth
                this.sliderWidth = val
                if (val) {
                    // Calculate slider height on ratio 1320:450
                    const height = Math.floor((val * 450) / 1320)
                    this.sliderHeight = height
                    // Display only 4 card if more than that should scrool down
                    this.sliderItemHeight = Math.ceil(height / 4) - 10
                }
            } else {
                setTimeout(() => {
                    this.getSliderImageWidth()
                }, 1000)
            }
        },
        limitText(text) {
            if (text?.length > 30) {
                return text?.substring(0, 30) + '...'
            } else {
                return text
            }
        },
        openHyperLink(item) {
            if (!item) return
            if (!item[this.handleCookie('wl_locale')]) return
            this.openNewBrowserTab(item[this.handleCookie('wl_locale')])
        },
    },
}
