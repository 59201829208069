// State
export const state = () => ({
    loading: false,
    bankAccount: {
        account_holder_name: null,
    },
    playerCryptoWallets: [],
    getPlayerCryptoWalletsLoading: false,
    getPlayerBankAccountLoading: null, // This one should be null at default, don't change this
})

// Actions
export const actions = {
    // Get bank account
    async getBankPlayerAccount({ commit }) {
        commit('setGetPlayerBankAccountLoading', true)
        try {
            const response = await this.$axios.get('/player-bank-account')
            commit('setBankAccount', response.data)
            commit('setGetPlayerBankAccountLoading', false)
        } catch (error) {
        } finally {
            commit('setGetPlayerBankAccountLoading', false)
        }
    },
    // Insert bank account
    async doInsertPlayerBankAccount({ commit, dispatch }, form) {
        commit('setLoading', true)
        try {
            await this.$axios.post('/player-bank-account', form)
            dispatch('getBankPlayerAccount')
            return true
        } catch (error) {
            return false
        } finally {
            commit('setLoading', false)
        }
    },
    // Update bank account
    async doUpdatePlayerBankAccount({ commit, dispatch }, { form, accountId }) {
        commit('setLoading', true)
        try {
            await this.$axios.put('/player-bank-account/' + accountId, form)
            dispatch('getBankPlayerAccount')
            commit('setLoading', false)
            return true
        } catch (error) {
            return false
        } finally {
            commit('setLoading', false)
        }
    },
    // Delete bank account
    async doDeletePlayerBankAccount({ commit, dispatch }, accountId) {
        commit('setLoading', true)
        try {
            await this.$axios.delete('/player-bank-account/' + accountId)
            dispatch('getBankPlayerAccount')
            return true
        } catch (error) {
        } finally {
            commit('setLoading', false)
        }
    },
    async createPlayerCryptoWallet({ dispatch }, form) {
        try {
            await this.$axios.post('/player-crypto-wallet', form)
            dispatch('playerCryptoWallet')
            return true
        } catch (error) {}
    },
    async playerCryptoWallet({ commit }, params) {
        commit('setGetPlayerCryptoWalletsLoading', true)
        try {
            const response = await this.$axios.get('/player-crypto-wallet', {
                params,
            })
            commit('setPlayerCryptoWallets', response.data)
        } catch (error) {
        } finally {
            commit('setGetPlayerCryptoWalletsLoading', false)
        }
    },
    async deletePlayerCryptoWallet({ dispatch }, id) {
        try {
            await this.$axios.delete(`/player-crypto-wallet/${id}`)
            dispatch('playerCryptoWallet')
            return true
        } catch (error) {
            return false
        }
    },
    async updatePlayerCryptoWallet({ dispatch }, form) {
        const id = form.id
        delete form.id
        try {
            await this.$axios.put(`/player-crypto-wallet/${id}`, form)
            dispatch('playerCryptoWallet')
            return true
        } catch (error) {
            return false
        }
    },
}

// Mutations
export const mutations = {
    setLoading: (state, v) => {
        state.loading = v
    },
    setGetPlayerBankAccountLoading: (state, v) => {
        state.getPlayerBankAccountLoading = v
    },
    setBankAccount: (state, v) => {
        if (v?.player_bank_accounts) {
            v.player_bank_accounts = v.player_bank_accounts.sort(
                (b, a) => a.is_primary - b.is_primary
            )
        }
        state.bankAccount = v
    },
    setPlayerCryptoWallets: (state, v) => {
        const accounts = v.sort((b, a) => a.is_primary - b.is_primary)
        state.playerCryptoWallets = accounts
    },
    setGetPlayerCryptoWalletsLoading: (state, v) => {
        state.getPlayerCryptoWalletsLoading = v
    },
}

// Getters
export const getters = {
    bankAccount: (state) => state.bankAccount,
    loading: (state) => state.loading,
    primaryBankAccount: (state) => {
        return state.bankAccount.player_bank_accounts
            ? state.bankAccount.player_bank_accounts.find(
                  (o) => o.is_primary === true
              )
            : {}
    },
}
