
import { mapState, mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
export default {
    name: 'Theme3NavBar',
    mixins: [cms],
    computed: {
        ...mapState({
            isApp: (state) => state.app.isApp,
        }),
        ...mapGetters({
            getRegistrationSuspendByCurrency:
                'settings/getRegistrationSuspendByCurrency',
        }),
    },
}
